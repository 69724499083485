.App {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 0 10px;
  padding-bottom: 10vmin;
}

.App-logo {
  height: 20vmin;
  min-height: 192px;
  pointer-events: none;
  display: inline;
  padding-bottom: 4vmin;
}

.App-header {
  background-color: #282c34;
  padding-top: 7vmin;
  padding-bottom: 4vmin;
  min-height: 15vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ffffff;
}

.App-link {
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  font-size: calc(40px + 3vmin);
}

.App-headnav {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 12px;
  justify-content: space-between;
  padding-bottom: 2vmin;
}

@media screen and (max-width: 900px) {
  .App-headnav {
    flex-direction: column;
  }

  .App-headnav-item {
    width: 100% !important;
    margin: 10px 0px;
  }

  .App-headnav-item-main {
    margin: 5px 0px;
  }
}

a {
  color: white;
}

.App-headnav-item {
  background-color: #f7f7f7;
  min-width: 120px;
  width: 30%;
  color: #121212;
  vertical-align: middle;
  display: inline-block;
  margin-top: 5px;
  text-decoration: none;
  height: 80px;
  line-height: 78px;
  min-width: 280px;
  font-size: 20px;
  cursor: pointer;
}

.App-headnav-item-main {
  background-color: #eff8ee;
  min-width: 120px;
  width: 30%;
  color: #121212;
  height: 90px;
  margin-top: 0px;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  line-height: 82px;
  font-weight: 700;
  min-width: 280px;
  font-size: 22px;
  cursor: pointer;
}

.App-headnav-item:hover {
  background-color: #e0e0e0;
}

.App-headnav-item-text {
  vertical-align: middle;
  font-weight: 600;
  height: 40px;
  text-decoration: none;
}

.App-headnav-item-text-small {
  vertical-align: middle;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.App-description-head {
  font-size: 18px;
  font-weight: 400;
}

.App-description {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
}

.App-description p {
  font-size: 16px;
}
